import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import AOS from 'aos';
import getTheme from '../theme';

export default function Page({children}) {

    React.useEffect(()=> {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles);
        }

        AOS.init({
            once: true,
            delay: 0,
            duration: 800,
            offset: 0,
            easing: 'ease-in-out',
        });
    }, []);

    return (
        <ThemeProvider theme={getTheme()}>
            <CssBaseline />
            <Paper elevation={0}>{children}</Paper>
        </ThemeProvider>
    );
};

Page.propTypes = {
    children: PropTypes.node.isRequired,
};