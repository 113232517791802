import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useTheme} from '@mui/material/styles';
import {Topbar, Footer, MobileMenu} from './components';
import {AppBar, Box, Collapse, Divider, useMediaQuery} from '@mui/material';
import Container from '../../components/Container';

import pages from '../navigation';

const Main = ({children}) => {

    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [openMobileMenu, setOpenMobileMenu] = useState(false);

    const handleMobileMenuClick = () => {
        setOpenMobileMenu(!openMobileMenu);
    };

    const open = isMd ? false : openMobileMenu;

    return (
        <Box>
            <AppBar
                position={'static'}
                sx={{
                    top: 0,
                    backgroundColor: theme.palette.background.paper,
                }}
                elevation={0}
            >
                <Container paddingY={1}>
                    <Topbar handleMobileMenuClick={handleMobileMenuClick} pages={pages}/>
                </Container>
            </AppBar>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Container paddingY={0}>
                    <MobileMenu pages={pages}/>
                </Container>
            </Collapse>
            <main>
                {children}
                <Divider/>
            </main>
            <Container paddingY={4}>
                <Footer/>
            </Container>
        </Box>
    );
};

Main.propTypes = {
    children: PropTypes.node,
};

export default Main;