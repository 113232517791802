/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const WhoWeAre = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Grid container spacing={6} direction={isMd ? 'row' : 'column'}>
        <Grid
          item
          container
          alignItems={'center'}
          justifyContent="center"
          xs={12}
          md={6}
        >
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
              What's in a name?
            </Typography>
            <Typography component={'p'} color={'text.secondary'}>
              A house culture is used to describe the community of bacteria and yeast that can transform food into its
              final beautiful combination of flavors and aromas that will leave your taste buds dancing. It is a
              magical thing of value that no one else can have since it is unique to them and cannot be recreated by
              someone else.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          container
          alignItems={'center'}
          justifyContent="center"
          xs={12}
          md={6}
        >
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
              Our Promise
            </Typography>
            <Typography component={'p'} color={'text.secondary'}>
              At House Culture Brewing we are dedicated to producing simple, real foods which are made with only real
              ingredients.  Real is better, this means No Preservatives, No Artificial Flavors, No Compromises.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WhoWeAre;
