import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Container from '../../../../components/Container';
import {IconButton} from '@mui/material';
import InstagramIcon from '../../../../components/InstagramIcon';

const Socials = () => {

    return (
        <Container>
            <Box component={Card} boxShadow={3} paddingY={3}>
                <CardContent>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <Box marginBottom={4}>
                            <Typography
                                variant="h4"
                                align={'center'}
                                gutterBottom
                                sx={{ fontWeight: 700 }}
                            >
                                Follow us on our socials
                            </Typography>
                            <Typography
                                variant="h6"
                                align={'center'}
                                color={'text.secondary'}
                            >
                                Get the latest news, updates and specials
                            </Typography>
                            <Typography align={'center'}>
                                <IconButton component="a" target="blank"
                                            href="https://www.instagram.com/houseculturebrewing/">
                                    <InstagramIcon fontSize={"large"} />
                                </IconButton>
                            </Typography>
                        </Box>
                    </Box>
                </CardContent>
            </Box>
        </Container>
    );
};

export default Socials;
