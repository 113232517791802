import {Instagram} from '@mui/icons-material';
import React from 'react';

const InstagramIcon = ({fontSize}) => (
    <>
        <svg width={0} height={0}>
            <linearGradient id="exampleColors" x1={1} y1={0} x2={1} y2={1} gradientTransform="rotate(45)">
                <stop offset='0%' stopColor="#7637FA" />
                <stop offset='50%' stopColor="#FF0069" />
                <stop offset='100%' stopColor="#FED602" />
            </linearGradient>
        </svg>
        <Instagram fontSize={fontSize} sx={{ fill: "url(#exampleColors)" }} />
    </>
);

export default InstagramIcon;