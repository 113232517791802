import React from 'react';
import Main from '../../layouts/Main';
import {Box} from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import Banner from './components/Banner';
import Features from './components/Features';
import ProductHighlight from './components/ProductHighlight';
import Container from '../../components/Container';
import CallToAction from './components/CallToAction';
import Socials from './components/Socials';
import ReactGA from 'react-ga4';

const IndexView = () => {

    ReactGA.send({
        hitType: "pageview",
        page: "/",
        title: "Home",
    });
    
    const theme = useTheme();

    return(
        <Main>
            <Box
                bgcolor={'alternate.main'}
                sx={{
                    position: 'relative',
                    '&::after': {
                        position: 'absolute',
                        content: '""',
                        width: '30%',
                        zIndex: 1,
                        top: 0,
                        left: '5%',
                        height: '100%',
                        backgroundSize: '18px 18px',
                        backgroundImage: `radial-gradient(${alpha(
                            theme.palette.primary.dark,
                            0.4,
                        )} 20%, transparent 20%)`,
                        opacity: 0.2,
                    },
                }}
            >
                <Box position={'relative'} zIndex={3}>
                    <Banner />
                </Box>
            </Box>
            <Box>
            <Features />
            </Box>
            <Box bgcolor={'alternate.main'}>
                <Container>
                    <ProductHighlight />
                </Container>
            </Box>
            <Box bgcolor={'alternate.main'}>
                <CallToAction />
            </Box>
            <Socials />
        </Main>
    );
};

export default IndexView;