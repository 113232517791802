import React from 'react';
import PropTypes from 'prop-types';

import {NavItem} from './components';
import {alpha, useTheme} from '@mui/material/styles';
import {Box, Button, Link} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import BuyNowButton from '../../../../components/OrderNow';

const Topbar = ({handleMobileMenuClick, pages = []}) => {
    const theme = useTheme();

    return (<Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        width={1}
    >
        <Box
            display={'flex'}
            component="a"
            href="/"
            title="House Culture Brewing"
            width={{xs: 100, md: 120}}
        >
            <Box
                component={'img'}
                    src={process.env.PUBLIC_URL + '/logo.svg'}
                height={1}
                width={1}
            />
        </Box>
        <Box sx={{display: {xs: 'none', md: 'flex'}}} alignItems={'center'}>
            {pages.map((p, i) => (<Box key={i} marginLeft={3}>
                {!p.children ? (<Link
                    href={p.href}
                    color={'text.primary'}
                    underline={'none'}
                    sx={{
                        '&:hover': {
                            color: 'primary.main',
                        },
                    }}
                >
                    {p.title}
                </Link>) : (<NavItem title={p.title} items={p.children} id={p.id}/>)}
            </Box>))}
            <Box marginLeft={3}>
                <BuyNowButton />
            </Box>
        </Box>
        <Box sx={{display: {xs: 'block', md: 'none'}}} alignItems={'center'}>
            <Button
                onClick={() => handleMobileMenuClick()}
                aria-label="Menu"
                variant={'outlined'}
                sx={{
                    borderRadius: 2, minWidth: 'auto', padding: 1, borderColor: alpha(theme.palette.divider, 0.2),
                }}
            >
                <MenuIcon/>
            </Button>
        </Box>
    </Box>);
};

Topbar.propTypes = {
    handleMobileMenuClick: PropTypes.func, pages: PropTypes.array,
};

export default Topbar;

