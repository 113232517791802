import React from 'react';
import {Box, Grid, IconButton, Link, Typography} from '@mui/material';
import InstagramIcon from '../../../../components/InstagramIcon';
import BuyNowButton from '../../../../components/OrderNow';

const Footer = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    width={1}
                    flexDirection={{xs: 'column', sm: 'row'}}
                >
                    <Box
                        display={'flex'}
                        width={80}
                    >
                        <Box
                            component={'img'}
                            src={process.env.PUBLIC_URL + '/logo.svg'}
                            height={1}
                            width={1}
                        />
                        <Box>
                            <IconButton component="a" target="blank"
                                        href="https://www.instagram.com/houseculturebrewing/">
                                <InstagramIcon fontSize={"small"}/>
                            </IconButton>
                        </Box>
                    </Box>
                    <Box display="flex" flexWrap={'wrap'} alignItems={'center'}>
                        <Box marginTop={1} marginRight={2}>
                        <Box
                            component={'img'}
                            src={process.env.PUBLIC_URL + '/logoiso.svg'}
                            height={55}
                            width={1}
                        />
                        </Box>
                        <Box marginTop={1} marginRight={2}>
                            <Link
                                underline="none"
                                component="a"
                                href="/"
                                color="text.primary"
                                variant={'subtitle2'}
                            >
                                Home
                            </Link>
                        </Box>
                        <Box marginTop={1} marginRight={2}>
                            <Link
                                underline="none"
                                component="a"
                                href="/about"
                                color="text.primary"
                                variant={'subtitle2'}
                            >
                                About
                            </Link>
                        </Box>
                        <Box marginTop={1} marginRight={2}>
                            <Link
                                underline="none"
                                component="a"
                                href="/events"
                                color="text.primary"
                                variant={'subtitle2'}
                            >
                                Events
                            </Link>
                        </Box>
                        <Box marginTop={1}>
                            <BuyNowButton />
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Typography
                    align={'center'}
                    variant={'subtitle2'}
                    color="text.secondary"
                    gutterBottom
                >
                    &copy; House Culture Brewing {new Date().getFullYear()}. All rights reserved
                </Typography>
            </Grid>
        </Grid>
    );
};

export default Footer;
