import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import Routes from './Routes';
import Page from './components/Page';
import ReactGA from 'react-ga4';
import 'aos/dist/aos.css';
import 'react-lazy-load-image-component/src/effects/blur.css';

function App() {

    ReactGA.initialize("G-LC3GV3EMYP");

    return (
        <Page>
            <BrowserRouter>
                <Routes/>
            </BrowserRouter>
        </Page>
    );
}

export default App;
