const pages = [
    {
        title: 'Products',
        href: '/',
        id: 'products',
        children: [
            {
                title: 'Kombucha',
                href: '/products/Kombucha',
                id: 'kombucha-page'
            },
            {
                title: 'Sourdough Bread',
                href: '/products/Bread',
                id: 'bread-page'
            },
            {
                title: 'Fermented Foods',
                href: '/products/Fermented',
                id: 'fermented-page'
            },
            {
                title: 'Hop Water',
                href: '/products/HopWater',
                id: 'hop-water-page'
            },
            // {
            //     title: 'Beer',
            //     href: '/products/Beer',
            //     id: 'beer-page'
            // },
        ],
    },
    {
        title: 'About',
        href: '/about',
        id: 'aboute-page',
    },
    {
        title: 'Events',
        href: '/events',
        id: 'events-page',
    },
];

export default pages;