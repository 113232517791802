import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Container from '../../../../components/Container';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';

const data = [
    {
        title: 'No Nonsense',
        subtitle:
            'No Preservatives, No Concentrates or Artificial Flavors.  No compromises. We are dedicated to using only real ingredients.',
        icon: (
            <LocalFloristIcon />
        ),
    },
    {
        title: 'Raw and Probiotic',
        subtitle:
            'We produce Naturally Fermented foods that are Raw, Probiotic, and Full of Living Cultures.',
        icon: (
            <PsychologyAltIcon />
        ),
    },
    {
        title: 'Promotes Healthy Gut',
        subtitle:
            'Traditionally Fermented foods that provide you with essential probiotics that can support gut-health.',
        icon: (
            <HealthAndSafetyIcon />
        ),
    },
];

const Features = () => {
    const theme = useTheme();
    return (
        <Container>
            <Box>
                <Box marginBottom={4}>
                    <Box marginBottom={2}>
                        <Typography
                            variant="h4"
                            color="text.primary"
                            align={'center'}
                            gutterBottom
                            sx={{
                                fontWeight: 700,
                            }}
                        >
                            Simple Ingredients, Complex Flavors
                        </Typography>
                        <Typography
                            variant="h6"
                            component="p"
                            color="text.secondary"
                            sx={{ fontWeight: 400 }}
                            align={'center'}
                        >
                            Dedicated to producing simple, naturally fermented foods that are packed with probiotics
                            for a healthy gut.
                        </Typography>
                    </Box>
                </Box>
                <Grid container spacing={2}>
                    {data.map((item, i) => (
                        <Grid item xs={12} md={4} key={i}>
                            <Box width={1} height={1}>
                                <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    alignItems={'center'}
                                >
                                    <Box
                                        component={Avatar}
                                        width={60}
                                        height={60}
                                        marginBottom={2}
                                        bgcolor={alpha(theme.palette.primary.main, 0.1)}
                                        color={theme.palette.primary.main}
                                    >
                                        {item.icon}
                                    </Box>
                                    <Typography
                                        variant={'h6'}
                                        gutterBottom
                                        sx={{ fontWeight: 500 }}
                                        align={'center'}
                                    >
                                        {item.title}
                                    </Typography>
                                    <Typography align={'center'} color="text.secondary">
                                        {item.subtitle}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Container>
    );
}

export default Features;