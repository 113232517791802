/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const Story = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Grid container spacing={4} direction={isMd ? 'row' : 'column'}>
        <Grid item container alignItems={'center'} xs={12} md={6}>
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
              Our story
            </Typography>
            <Typography component={'p'}>
              House Culture Brewing was born when three friends decided to take their love of fermentation and
              beverages to the next level.
              <br/>
              <br/>
              We create delicious, naturally fermented foods and refreshing drinks with
              real ingredients . Our ferments are not only delicious, they may be able to help support gut health all
              while infusing your diet with some well balanced flavor.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={6}
        >
          <Box maxWidth={500} width={1}>
            <Box
              component={'img'}
              src={
                'https://houseculturebrewing.s3.amazonaws.com/98832933-7814-4a7f-8273-6f7d186bb21f.jpg'
              }
              width={1}
              height={1}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Story;
