import React from 'react';
import {Routes as ReactRoutes, Route} from 'react-router-dom';
import viewRoutes from './views/routes';

const Routes = () => {
    return (
        <ReactRoutes>
            {viewRoutes.map((item, i) => (
                <Route key={i} path={item.path} element={item.renderer()}/>
            ))}
        </ReactRoutes>
    );
};

export default Routes;