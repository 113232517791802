import axios from 'axios';

export default axios.create({
    // baseURL: 'http://localhost:8080',
    baseURL: 'https://api.houseculturebrewing.com',
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
});