import React from 'react';
import Main from '../../layouts/Main';
import Container from '../../components/Container';
import ReactGA from 'react-ga4';
import Divider from '@mui/material/Divider';
import {
    Contact,
    Story,
    WhoWeAre,
} from './components';

const AboutView = () => {

    ReactGA.send({
        hitType: "pageview",
        page:"/about",
        title: "About",
    });

    return (
        <Main>
            <Container>
                <Story />
            </Container>
            <Container paddingTop={'0 !important'}>
                <WhoWeAre />
            </Container>
            <Container maxWidth={800} paddingY={'0 !important'}>
                <Divider />
            </Container>
            <Container paddingY={'20px'}>
                <Contact />
            </Container>
        </Main>
    );
};

export default AboutView;