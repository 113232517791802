import { responsiveFontSizes } from '@mui/material';
import {createTheme} from '@mui/material/styles';
import shadows from './shadows';
import { palette } from './palette';

const getTheme = () =>
    responsiveFontSizes(
        createTheme({
            palette: palette,
            shadows: shadows(),
            typography: {
                fontFamily: '"Inter", sans-serif',
                button: {
                    textTransform: 'none',
                    fontWeight: 'medium',
                },
            },
            zIndex: {
                appBar: 1200,
                drawer: 1300,
            },
            components: {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            fontWeight: 400,
                            borderRadius: 5,
                            paddingTop: 10,
                            paddingBottom: 10,
                        },
                        containedSecondary: { color: 'white' },
                    },
                },
                MuiInputBase: {
                    styleOverrides: {
                        root: {
                            borderRadius: 5,
                        },
                    },
                },
                MuiOutlinedInput: {
                    styleOverrides: {
                        root: {
                            borderRadius: 5,
                        },
                        input: {
                            borderRadius: 5,
                        },
                    },
                },
                MuiCard: {
                    styleOverrides: {
                        root: {
                            borderRadius: 8,
                        },
                    },
                },
            },
        }),
    );

export default getTheme;
