import React from 'react';
import {Button} from '@mui/material';

const BuyNowButton = ({size="small"}) => (
    <Button
        variant="contained"
        color="primary"
        component="a"
        target="blank"
        href="https://houseculturebrewing.square.site"
        size={size}
    >
        Order Now
    </Button>
);

export default BuyNowButton;