import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useTheme} from '@mui/material/styles';
import {Box, Grid, Link, Popover, Typography} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const NavItem = ({title, id, items}) => {
    const theme = useTheme();

    const [anchorEl, setAnchorEl] = useState(null);
    const [openedPopoverId, setOpenedPopoverId] = useState(null);

    const handleClick = (event, popoverId) => {
        setAnchorEl(event.target);
        setOpenedPopoverId(popoverId);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenedPopoverId(null);
    };

    return (
        <Box>
            <Box
                display={'flex'}
                alignItems={'center'}
                aria-describedby={id}
                sx={{cursor: 'pointer'}}
                onClick={(e) => handleClick(e, id)}
            >
                <Typography
                    color={'text.primary'}
                    sx={{
                        '&:hover': {
                            color: 'primary.main',
                        },
                    }}
                >
                    {title}
                </Typography>
                <ExpandMoreIcon
                    sx={{
                        marginLeft: theme.spacing(1 / 4),
                        width: 16,
                        height: 16,
                        transform: openedPopoverId === id ? 'rotate(180deg)' : 'none',
                        color: 'text.primary',
                    }}
                />
            </Box>
            <Popover
                elevation={3}
                id={id}
                open={openedPopoverId === id}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                sx={{
                    '.MuiPaper-root': {
                        maxWidth: 220,
                        padding: 1,
                        marginTop: 2,
                        borderTopRightRadius: 0,
                        borderTopLeftRadius: 0,
                        borderBottomRightRadius: 8,
                        borderBottomLeftRadius: 8,
                        borderTop: `2px solid ${theme.palette.primary.main}`,
                    },
                }}
            >
                <Grid container spacing={0}>
                    {items.map((p, i) => (
                        <Grid item key={i} xs={items.length > 12 ? 6 : 12}>
                            <Link
                                href={p.href}
                                underline={'none'}
                                color={'text.primary'}
                                sx={{
                                    display: 'flex',
                                    p: 1,
                                    borderRadius: 1,
                                    '&:hover': {
                                        bgcolor: 'alternate.main',
                                    },
                                }}
                            >
                                {p.title}
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </Popover>
        </Box>
    );
};

NavItem.propTypes = {
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
};

export default NavItem;