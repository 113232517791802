import React from 'react';
import Main from '../../layouts/Main';
import {Box, Card, CardContent, CircularProgress, Grid, Tooltip, Typography} from '@mui/material';
import Container from '../../components/Container';
import api from '../../api';
import {useTheme} from '@mui/material/styles';
import SyncIcon from '@mui/icons-material/Sync';
import ReactGA from 'react-ga4';

class EventsView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMounted: false,
            events: [],
            isLoading: true,
        };
    }

    async retrieveData() {
        let self = this;

        api.get(`/events`)
            .then(function (response) {
                let {isMounted} = self.state;
                if (isMounted) {
                    self.setState({events: response.data, isLoading: false});
                }
            }).catch(function (error) {
            console.log('Error loading Category ::', error);
        });
    }

    componentDidMount() {
        this.setState({isMounted: true});
        this.retrieveData();
    }

    componentWillUnmount() {
        this.setState({isMounted: false});
    }

    getVenueString(venue) {
        return(
        <Typography display="inline" variant={'subtitle2'} color="text.secondary" gutterBottom={true}>
            <Typography display="inline" fontWeight={700} variant={'subtitle2'} color="text.primary">{venue.name}</Typography>
            {' ' + venue.address.street + ', ' + venue.address.city + ', ' + venue.address.state + ', ' + venue.address.zip}
        </Typography>);
    }

    getEventTime(eventDate, startTime, endTime, recurring, cancelled) {
        if(cancelled){
            return(
                <Box>
                    <Typography
                        display="inline"
                        color="text.third" variant="overline"
                        sx={{textDecoration: 'line-through'}}
                    >
                        {new Date(eventDate).toLocaleString('en-US', {dateStyle: 'short'})}
                        {' ' + startTime + ' - ' + endTime}
                        {
                            recurring ? <Tooltip title="Recurring"><SyncIcon color={'info'} fontSize={'tiny'}/></Tooltip> : ''
                        }
                    </Typography>
                    <Typography display="inline" variant="overline" color="text.third">{' '} Cancelled</Typography>
                </Box>
            );
        }

        return (
            <Typography color="text.secondary" variant="overline">
                {new Date(eventDate).toLocaleString('en-US', {dateStyle: 'short'})}
                {' ' + startTime + ' - ' + endTime}
                {
                    recurring ? <Tooltip title="Recurring"><SyncIcon color={'info'} fontSize={'tiny'}/></Tooltip> : ''
                }
            </Typography>
        );
    }

    render() {

        ReactGA.send({
            hitType: "pageview",
            page:"/events",
            title: "Events",
        });

        if (this.state.isLoading)
            return (
                <Main bgcolor={'background.paper'}>
                    <Container>
                        <Box marginTop={2}>
                            <CircularProgress/>
                        </Box>
                    </Container>
                </Main>
            );

        if (!this.state.isLoading && this.state.events.length < 1)
            return (
                <Main>
                    <Container>
                        <Box>
                            No Events
                        </Box>
                    </Container>
                </Main>
            );

        const EventList = () => {
            const theme = useTheme();

            const day = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];


            return (
                <Main>
                    <Container>
                        <Grid container rowSpacing={4}>
                            {this.state.events.map((item, i) => (
                                <Grid container item key={i} columnSpacing={1}>
                                    <Grid item xs={1} alignContent={"center"}>
                                        <Box sx={{textAlign: 'center'}}>
                                            <Typography fontWeight={100} variant={'overline'}
                                                        sx={{textTransform: 'uppercase'}}>{day[new Date(item.eventDate).getDay()]}</Typography>
                                            <Typography fontWeight={700}
                                                        variant={'h6'}>{new Date(item.eventDate).getDate()}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Box
                                            component={Card}
                                            width={1}
                                            height={1}
                                            borderRadius={0}
                                            boxShadow={0}
                                            display={'flex'}
                                            flexDirection={{
                                                xs: 'column',
                                                md: 'row-reverse',
                                            }}
                                            sx={{backgroundImage: 'none', bgcolor: 'transparent'}}
                                        >
                                            <Box
                                                sx={{
                                                    width: {xs: 1, md: '50%'},
                                                }}
                                            >
                                                <Box
                                                    component={'img'}
                                                    height={1}
                                                    width={1}
                                                    src={'https://houseculturebrewing.s3.amazonaws.com/' + item.venue.photo}
                                                    alt="..."
                                                    sx={{
                                                        objectFit: 'cover',
                                                        maxWidth: 360,
                                                        filter: theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    }}
                                                />
                                            </Box>
                                            <CardContent
                                                sx={{
                                                    paddingX: {xs: 1, sm: 2, md: 4},
                                                    paddingY: {xs: 2, sm: 4},
                                                    width: {xs: 1, md: '50%'},
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                {this.getEventTime(item.eventDate, item.startTime, item.endTime, item.recurring, item.cancelled)}
                                                <Typography
                                                    variant={'body'}
                                                    fontSize={18}
                                                    fontWeight={700}
                                                    sx={{textTransform: 'uppercase'}}
                                                >
                                                    {item.name}
                                                </Typography>
                                                {this.getVenueString(item.venue)}
                                                <Typography  color="text.primary">
                                                    {item.description}
                                                </Typography>

                                            </CardContent>
                                        </Box>
                                    </Grid>
                                </Grid>
                            ))};
                        </Grid>
                    </Container>
                </Main>
            );
        };

        return <EventList/>;
    }
};

export default EventsView;