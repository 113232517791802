import React from 'react';
import PropTypes from 'prop-types';
import {Box, Button, Link} from '@mui/material';
import {NavItem} from './components';

const MobileMenu = ({pages = []}) => {
    return (
        <Box>
            {pages.map((p, i) => (
                <Box key={i} marginY={2}>
                    {!p.children ? (
                        <Link
                            href={p.href}
                            color={'text.primary'}
                            underline={'none'}
                            sx={{
                                '&:hover': {
                                    color: 'primary.main',
                                },
                            }}
                        >
                            {p.title}
                        </Link>
                    ) : (
                        <NavItem title={p.title} items={p.children}/>
                    )}
                </Box>
            ))}
            <Box marginY={2}>
                <Button
                    variant="contained"
                    color="primary"
                    component="a"
                    target="blank"
                    fullWidth
                    href="https://houseculturebrewing.square.site/"
                    size="small"
                >
                    Order Now
                </Button>
            </Box>
        </Box>
    );
};

MobileMenu.propTypes = {
    pages: PropTypes.array.isRequired,
};

export default MobileMenu;