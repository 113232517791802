import React from 'react';
import Main from '../../layouts/Main';
import {Box, CircularProgress, Grid} from '@mui/material';
import Container from '../../components/Container';

import {Image, Details} from './components';
import api from '../../api';
import PropTypes from 'prop-types';
import {withRouter} from '../../utils/withRouter';
import OrderNow from '../../components/OrderNow';
import ReactGA from 'react-ga4';

class ProductView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            product: {},
            isLoading: true,
        };
    }

    async retrieveData() {
        let self = this;
        api.get(`/products/id/${self.props.match.params.id}`)
            .then(function (response) {
                let {isMounted} = self.state;
                if (isMounted) {
                    self.setState({product: response.data, isLoading: false});
                }
            }).catch(function (error) {
            console.log('Error loading Category ::', error);
        });
    }

    componentDidMount() {
        this.setState({isMounted: true});
        this.retrieveData();
    }

    componentWillUnmount() {
        this.setState({isMounted: false});
    }

    render() {
        let product = this.state.product;

        ReactGA.send({
            hitType: "pageview",
            page:`/product/${this.props.match.params.id}`,
            title: "Product",
        });

        if (this.state.isLoading) {
            return (

                <Main bgcolor={'background.paper'}>
                    <Container>
                        <Box marginTop={2}>
                            <CircularProgress/>
                        </Box>
                    </Container>
                </Main>

            );
        }

        return (
            <Main>
                <Container>
                    <Box>
                        <Grid container spacing={{xs: 2, md: 4}}>
                            <Grid item xs={12} md={6}>
                                <Image name={product.name} photo={product.photo}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Details data={product}/>
                                <OrderNow />
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Main>
        );
    }
};

ProductView.propTypes = {
    match: PropTypes.any,
};

export default withRouter(ProductView);