import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';

const Image = (props) => {

    return(
        <Box>
            <Box
                sx={{
                    marginBottom: 2,
                    width: 1,
                    height: 'auto',
                    '& img': {
                        width: 1,
                        height: 1,
                        objectFit: 'cover',
                        borderRadius: 2,
                    },
                }}
            >
                <img src={'https://houseculturebrewing.s3.amazonaws.com/' + props.photo} alt={props.name} />
            </Box>
        </Box>
    );
};

Image.propTypes = {
    photo: PropTypes.string,
    name: PropTypes.string,
};

export default Image;