import React from 'react';
import Main from '../../layouts/Main';
import {
    Box,
    Card, CardContent, CardMedia,
    CircularProgress, Grid, Typography
} from '@mui/material';
import Container from '../../components/Container';

import api from '../../api';
import PropTypes from 'prop-types';
import {useTheme} from '@mui/material/styles';
import {withRouter} from '../../utils/withRouter';
import ReactGA from 'react-ga4';

class ProductListView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMounted: false,
            productsList: [],
            isLoading: true,
        };
    }

    async retrieveData() {
        let self = this;

        api.get(`/products/${self.props.match.params.type}`)
            .then(function (response) {
                let {isMounted} = self.state;
                if (isMounted) {
                    self.setState({productsList: response.data, isLoading: false});
                }
            }).catch(function (error) {
            console.log('Error loading Category ::', error);
        });
    }

    componentDidMount() {
        this.setState({isMounted: true});
        this.retrieveData();
    }

    componentWillUnmount() {
        this.setState({isMounted: false});
    }

    render() {

        ReactGA.send({
            hitType: "pageview",
            page:`/products/${this.props.match.params.type}`,
            title: "Product List",
        });

        if (this.state.isLoading)
            return (
                <Main bgcolor={'background.paper'}>
                    <Container>
                        <Box marginTop={2}>
                            <CircularProgress/>
                        </Box>
                    </Container>
                </Main>
            );

        if (!this.state.isLoading && this.state.productsList.length < 1)
            return (
                <Main bgcolor={'background.paper'}>
                    <Container>
                        <Box marginTop={2}>
                            <Typography align={'center'} variant={'h6'} fontWeight={700}>Coming Soon</Typography>
                        </Box>
                    </Container>
                </Main>
            );



        const ProductList = () => {

            const theme = useTheme();

            return (
                <Main>
                    <Container>
                        <Box>
                            <Grid container spacing={4}>
                                {this.state.productsList.map((item, i) => (
                                    <Grid item xs={12} sm={6} md={3} key={i}>
                                        <Box
                                            component={'a'}
                                            href={'/product/' + item.id}
                                            display={'block'}
                                            width={1}
                                            height={1}
                                            sx={{
                                                textDecoration: 'none',
                                                transition: 'all .2s ease-in-out',
                                                '&:hover': {
                                                    transform: `translateY(-${theme.spacing(1 / 2)})`,
                                                },
                                            }}
                                        >
                                            <Box
                                                component={Card}
                                                width={1}
                                                height={1}
                                                display={'flex'}
                                                flexDirection={'column'}
                                            >
                                                <CardMedia
                                                    image={'https://houseculturebrewing.s3.amazonaws.com/' + item.photo}
                                                    title={item.name}
                                                    sx={{
                                                        height: {xs: 340, md: 400},
                                                        filter:
                                                            theme.palette.mode === 'dark'
                                                                ? 'brightness(0.7)'
                                                                : 'none',
                                                    }}
                                                />
                                                <Box component={CardContent}>
                                                    <Typography align={'center'} variant={'h6'} fontWeight={700}
                                                                gutterBottom>
                                                        {item.name}
                                                    </Typography>
                                                    <Typography align={'center'} variant={'body2'}
                                                                color="text.secondary">
                                                        {item.description}
                                                    </Typography>
                                                </Box>
                                                <Box flexGrow={1}/>
                                            </Box>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </Container>
                </Main>
            );
        }

        return <ProductList/>;
    }
};

ProductListView.propTypes = {
    match: PropTypes.any,
};

export default withRouter(ProductListView);