import React from 'react';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import Container from '../../../../components/Container';

const CallToAction = () => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    return (
        <Container>
            <Grid container spacing={4} direction={isMd ? 'row' : 'column-reverse'}>
                <Grid item xs={12} md={6}>
                    <Box marginBottom={4}>
                        <Typography sx={{ fontWeight: 700 }} variant={'h4'} gutterBottom>
                            Where Can you Find us?
                        </Typography>
                        <Typography variant={'h6'} component={'p'} color={'text.secondary'}>
                            Be sure to visit us at one of the weekly farmers markets in the greater Austin area.  Check out our events page to find out where we will be next.
                        </Typography>
                    </Box>
                    <Box>
                        <Button variant={'contained'} size={'large'} href={'/events'}>
                            Events
                        </Button>
                    </Box>
                </Grid>
                <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    md={6}
                    sx={{
                        display: { xs: 'none', md: 'flex' },
                    }}
                >
                    <Box component={Card} boxShadow={4} height={1} width={1}>
                        <Box
                            component={CardMedia}
                            height={1}
                            width={1}
                            minHeight={400}
                            image={process.env.PUBLIC_URL + '/farmers_market.jpg'}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default CallToAction;
