import IndexView from './IndexView';
import AboutView from './AboutView';
import ProductListView from './ProductListView';
import ProductView from './ProductView';
import EventsView from './EventsView';

const routes = [
    {
        path: '/',
        renderer: (params = {}) => <IndexView {...params} />,
    },
    {
        path: '/about',
        renderer: (params = {}) => <AboutView {...params} />,
    },
    {
        path: '/products/:type',
        renderer: (params = {}) => <ProductListView {...params} />,
    },
    {
        path: '/product/:id',
        renderer: (params = {}) => <ProductView {...params} />,
    },
    {
        path: '/events',
        renderer: (params = {}) => <EventsView {...params} />,
    },
];

export default routes;