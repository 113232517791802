import React from 'react';
import PropTypes from 'prop-types';
import {Box, Typography} from '@mui/material';

const Details = (props) => {

    let data = props.data;

    const getMetadata = (type, metadata) => {
        if("Beer".localeCompare(type) === 0) {
            return (
                <Box marginY={3}>
                    <Typography variant={'caption'} fontWeight={500} color={'text.secondary'}>
                        {metadata.beerType} | {metadata.abv}% ABV
                    </Typography>
                </Box>
            );
        } else  {
            return(
                <Box marginY={3}>
                    <Typography variant={'caption'} fontWeight={500} color={'text.secondary'}>
                        <strong>Ingredients</strong>: {metadata.ingredients}
                    </Typography>
                </Box>
            );
        }
    }

    return (
        <Box>
            <Box>
                <Typography display="inline" variant={'body'} fontSize={25} fontWeight={700}>
                    {data.name} <Typography display="inline" variant={'subtitle2'} fontWeight={700}>{data.metadata.size}</Typography>
                </Typography>
            </Box>
            {getMetadata(data.type, data.metadata)}
            <Box marginY={3}>
                <Typography variant={'subtitle1'} color={'text.secondary'}>
                    {data.description}
                </Typography>
            </Box>
        </Box>
    );
};

Details.propTypes = {
    data: PropTypes.any,
};

export default Details;